import { HttpErrorResponse } from '@angular/common/http';
//----------------------------------------------
import {
  MealTime,
  PaymentRequestType,
  Seat,
  SelfServiceTicketItem,
  SimpleDiner,
  TakeoutOrderSetup,
  Ticket,
  TicketEventData,
  TicketLockedData
} from 'src/app/models';

import { NormalizedEntities } from 'src/app/models/shared/normalized-entities.model';

export class SelectTicket {
  static readonly type = '[Ticket] Select a Ticket';
  constructor(public readonly ticketId: string = null) {}
}

export class LoadTicketDetail {
  static readonly type = '[Ticket] Load Ticket Detail';
  constructor(
    public readonly ticketUuid: string,
    public readonly selectTicket = false
  ) {}
}

export class LoadTicketDetailSuccess {
  static readonly type = '[Ticket] Load Ticket Detail Success';
  constructor(
    public readonly ticket: Ticket,
    public readonly selectTicket = false
  ) {}
}

export class GetTicketsSuccess {
  static readonly type = '[Ticket] Get Tickets Success';
  constructor(public entities: NormalizedEntities) {}
}

export class UpdateTicket {
  static readonly type = '[Ticket] Update Ticket';
  constructor(public readonly ticket: Partial<Ticket>) {}
}

export class VoidTicket {
  static readonly type = '[Ticket] Void a Ticket';
  constructor(
    public operator_id: number,
    public approving_operator_id: number,
    public void_reason: string
  ) {}
}

export class CancelTicket {
  static readonly type = '[Ticket] Cancel a Ticket';
  constructor(public readonly ticketUuid?: string) {}
}

export class CloseTicket {
  static readonly type = '[Ticket] Close a Ticket';
  constructor(
    public readonly ticketUuid: string,
    public readonly paymentTypes: PaymentRequestType[]
  ) {}
}

export class CloseTicketSuccess {
  static readonly type = '[Ticket] Close a Ticket Success';
}

export class CloseTicketError {
  static readonly type = '[Ticket] Close a Ticket Error';
  constructor(public readonly errorMessage: string) {}
}

export class MealPlanPaymentError {
  static readonly type = '[Ticket] Meal Plan Payment Error';
  constructor(
    public readonly ticketUuid: string,
    public readonly errorMessage: string
  ) {}
}

export class AddTicketMealPlan {
  static readonly type = '[Ticket] Add Ticket Meal Plan';
  constructor(
    public readonly mealPlanId: number,
    public readonly ticketUuid: string = null
  ) {}
}

export class AddTicketMealPlanSuccess {
  static readonly type = '[Ticket] Add Ticket Meal Plan Success';
  constructor(public ticket: Ticket, public selectTicket: boolean = false) {}
}

export class RemoveTicketMealPlan {
  static readonly type = '[Ticket] Remove Ticket Meal Plan';
  constructor(public ticketUuid: string) {}
}

export class AddTicketDiscount {
  static readonly type = '[Ticket] Add Ticket Discount';
  constructor(public discount_id: number) {}
}

export class AddTicketDiscountError {
  static readonly type = '[Ticket] Add Ticket Discount Error';
  constructor(public error: HttpErrorResponse) {}
}

export class RemoveTicketDiscount {
  static readonly type = '[Ticket] Remove Ticket Discount';
  constructor(public discount_id: number) {}
}

export class RemoveTicketSuccess {
  static readonly type = '[Ticket] Remove Ticket Success';
  constructor(
    public readonly device_ticket_uuid: string,
    public readonly removeTicket: boolean = false
  ) {}
}

export class UpdateTicketSuccess {
  static readonly type = '[Ticket] Update Ticket Success';
  constructor(public readonly data: Partial<Ticket>) {}
}

export class CreateTicket {
  static readonly type = '[Ticket] Create a Ticket';
  constructor(public readonly defaults: Partial<Ticket> = null) {}
}

export class CreateTicketSuccess {
  static readonly type = '[Ticket] Create a Ticket Success';
  constructor(public ticket: Ticket, public selectTicket: boolean = true) {}
}

export class CreateTicketError {
  static readonly type = '[Ticket] Create Ticket Error';
  constructor(public errorMessage: string) {}
}

export class AddDinerToTicket {
  static readonly type = '[Ticket] Add Diner to Ticket';
  constructor(
    public readonly diner: SimpleDiner,
    public readonly forceApplyMealPlan = false
  ) {}
}

export class AddDinerToTicketSuccess {
  static readonly type = '[Ticket] Add Diner to Ticket Success';
  constructor(
    public readonly diner: SimpleDiner,
    public readonly addMealPlan = true,
    public readonly getDinerDetail = true,
    public readonly forceApplyMealPlan = false,
    public readonly seatId?: number
  ) {}
}

export class RemoveDinerFromTicket {
  static readonly type = '[Ticket] Remove Diner from Ticket';
}

export class RemoveGuestOfDinerFromTicket {
  static readonly type = '[Ticket] Remove Guest of Diner from Ticket';
}

export class RemoveDinerFromTicketSuccess {
  static readonly type = '[Ticket] Remove Diner from Ticket Success';
  constructor(public readonly ticket?: Ticket) {}
}

export class RemoveDinerFromTicketError {
  static readonly type = '[Ticket] Remove Diner from Ticket Error';
  constructor(public readonly errorMessage: string) {}
}

export class AddTicketItems {
  static readonly type = '[Ticket] Add Ticket Items';
  constructor(
    public readonly ticketCalculations: Partial<Ticket>,
    public readonly ticketItemsUuids: string[]
  ) {}
}

export class AddTicketItemError {
  static readonly type = '[Ticket] Add Ticket Item Error';
  constructor(public readonly httpErrorResponse: HttpErrorResponse) {}
}

export class FireIndividualOrder {
  static readonly type = '[Ticket] Fire Individual Order';
  constructor(
    public readonly ticketItemUuid?: string,
    public readonly course?: number,
    public readonly seat?: Seat
  ) {}
}

export class FireListIndividualOrder {
  static readonly type = '[Ticket] Fire List Individual Order';
  constructor(
    public listIndividualOrder: {
      ticketItemUuid?: string;
      course?: number;
    }[],
    public seat?: Seat
  ) {}
}

export class UpdateFireTableSuccess {
  static readonly type = '[Ticket] Update Fire Table Success';
  constructor(
    public readonly tableId: number,
    public readonly course?: number
  ) {}
}

export class RemoveTicketItem {
  static readonly type = '[Ticket] Remove Ticket Item';
  constructor(public ticketItemUuids: string[]) {}
}

export class RemoveTicketItemSuccess {
  static readonly type = '[Ticket Items] Remove a Ticket Item Success';
  constructor(
    public readonly ticket: Ticket,
    public readonly ticketItemUuid?: string,
    public readonly clearAllModifiers = false
  ) {}
}

export class GetLastQuickServiceTicket {
  static readonly type = '[Ticket] Get Last Quick Service Ticket';
}

export class GetLastQuickServiceTicketSuccess {
  static readonly type = '[Ticket] Get Last Quick Service Ticket Success';
  constructor(
    public readonly ticket: Ticket,
    public readonly selectTicket: boolean = true
  ) {}
}

export class UpdateLocalTicketRelationships {
  static readonly type = '[Ticket] Update Local Ticket Dependencies';
  constructor(public entities: NormalizedEntities) {}
}

export class ToggleTicketVisibility {
  static readonly type = '[Small UI] Toggle Ticket Visibility';
  constructor(public isVisible?: boolean) {}
}

export class CreateTicketWsEvent {
  static readonly type = '[WS Events] Create Ticket';
  constructor(public wsData: TicketEventData) {}
}

export class CreateTicketWsEventSuccess {
  static readonly type = '[WS Events] Create Ticket Success';
  constructor(
    public readonly ticket: Ticket,
    public readonly selectTicket: boolean = false
  ) {}
}

export class CloseTicketWsEvent {
  static readonly type = '[WS Events] Remove Ticket';
  constructor(public readonly wsData: TicketEventData) {}
}

export class PrintTicket {
  static readonly type = '[Ticket] Print Ticket';

  constructor(
    public ticketUuid?: string,
    public open_cash_drawer: boolean = false
  ) {}
}

export class PrintLastReceipt {
  static readonly type = '[Ticket] Print Last Receipt';
}

export class LockTicket {
  static readonly type = '[Ticket] Lock Ticket';
}

export class UnlockTicket {
  static readonly type = '[Ticket] Unlock Ticket';
  constructor(public readonly device_ticket_uuid: string) {}
}

export class LockOrUnlockTicketSuccess {
  static readonly type = '[Ticket] Lock or Unlock Ticket Success';
  constructor(
    public readonly ticketUuid: string,
    public readonly locked: boolean,
    public readonly locked_by_operator_id: number,
    public readonly wsNotified: boolean = false
  ) {}
}

export class AddTaxExemption {
  static readonly type = '[Ticket] Add Tax Exemption';
}

export class RemoveTaxExemption {
  static readonly type = '[Ticket] Remove Tax Exemption';
}

export class TicketMealChange {
  static readonly type = '[Ticket] Ticket Meal Change';
  constructor(public readonly meal_id: number) {}
}

export class ChangeTicketMeal {
  static readonly type = '[Ticket] Change Ticket Meal';
  constructor(public readonly meal: MealTime) {}
}

export class ChangeTicketMealSuccess {
  static readonly type = '[Ticket] Change Ticket Meal Success';
  constructor(public readonly newTicket: Ticket) {}
}

export class SetupTakeoutOrder {
  static readonly type = '[Ticket Actions] Setup Takeout Order';
  constructor(public readonly takeoutOrderSetup: TakeoutOrderSetup) {}
}

export class SetupTakeoutOrderSuccess {
  static readonly type = '[Ticket Actions] Setup Takeout Order Success';
}

export class SetupTakeoutOrderError {
  static readonly type = '[Ticket Actions] Setup Takeout Order Error';
  constructor(public errorMessage: string) {}
}

export class CancelTakeoutTicket {
  static readonly type = '[Ticket] Cancel Takeout Ticket';
  constructor(public ticket_uuid: string, public operator_id?: number) {}
}

export class CancelTakeoutTicketSuccess {
  static readonly type = '[Ticket] Cancel Takeout Ticket Success';
}

export class CleanSelectTicket {
  static readonly type = '[Ticket] Clean Select Ticket';
}

export class SelectBackQSTicket {
  static readonly type = '[Ticket] Select Back QS Ticket';
  constructor(public ticketUuid: string) {}
}

export class CreateDraftTicket {
  static readonly type = '[Ticket] Create Draft Ticket';
}

export class RemoveAllSskTicketItems {
  static readonly type = '[SSK Ordering] Remove All Ticket Items';
  constructor(public readonly ticketItems: SelfServiceTicketItem[]) {}
}

export class RemoveAllSskTicketItemsError {
  static readonly type = '[SSK Ordering] Remove All Ticket Items Error';
  constructor(public readonly error: HttpErrorResponse) {}
}

export class TemporarySeatMealChange {
  static readonly type = '[Ticket] Temporary Seat Meal Change';
  constructor(public readonly meal_id: number) {}
}

export class TemporarySeatMealChangeSuccess {
  static readonly type = '[Ticket] Temporary Seat Meal Change Success';
  constructor(public readonly seat: Seat, public readonly ticket: Ticket) {}
}

export class CreateTicketForSeatSuccess {
  static readonly type = '[Ticket] Create a Ticket For Seat Success';
  constructor(public readonly ticket: Ticket) {}
}

export class TicketLockedChangedFromWS {
  static readonly type = '[Ticket] Ticket Locked Changed From WS';
  constructor(public readonly data: TicketLockedData) {}
}
export class TicketClosedWsEvent {
  static readonly type = '[WS Events] Ticket Closed';
  constructor(public readonly wsData: TicketEventData) {}
}

export class CancelServicesTicket {
  static readonly type = '[Ticket] Cancel Services Ticket';
  constructor(public readonly device_ticket_uuid: string) {}
}

export class SetTicketCreatingStatus {
  static readonly type = '[Ticket] SetTicketCreatingStatus';
  constructor(public readonly isCreating: boolean) {}
}

export class FireTicketAndClose {
  static readonly type = '[Self Service] Fire Ticket and Close';
  constructor(
    public readonly deviceTicketUuid: string,
    public readonly paymentTypes: PaymentRequestType[]
  ) {}
}

export class FireTicketAndCloseError {
  static readonly type = '[Self Service] Fire Ticket And close Error';
  constructor(public readonly errorMessage: string) {}
}

export class GetLocationTickets {
  static readonly type = '[Ticket] Get Location Tickets';
}

// export class AssignResidentForGuestTicket {
//   static readonly type = '[Ticket] Assign Resident For Guest Ticket';
//   constructor(public readonly guest_of_diner_id: number) {}
// }

export class AssignGuestMealPlan {
  static readonly type = '[Ticket] Assign Guest Meal Plan';
  constructor(public readonly diner_id: number) {}
}
