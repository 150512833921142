import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ToastController } from '@ionic/angular';
import {
  Action,
  Actions,
  ofActionSuccessful,
  Selector,
  State,
  StateContext,
  Store
} from '@ngxs/store';
import { tap, catchError, retry, switchMap, throwError, take } from 'rxjs';

import { PaymentRequestType, CreditCardProcessingType } from 'src/app/models';
import { PaymentService, TicketService } from 'src/app/services';
import { GlobalService } from 'src/app/services/global/global.service';
import { ExceptionError, HttpRequestError } from '../error/error.action';
import {
  LoadTicketDetailSuccess,
  MealPlanPaymentError,
  RemoveTicketSuccess,
  UpdateTicketSuccess
} from '../tickets/tickets.action';
import {
  GetDepartments,
  MakePayment,
  MakePaymentSuccess,
  GetGiftCard,
  GetGiftCardSuccess,
  InitiateGatewayTransaction,
  OnGatewayTransactionSuccess,
  OnGatewayTransactionError,
  OnRetryGatewayTransaction,
  GetBusinessLines,
  ResetDepartments,
  MakePaymentError,
  CancelGatewayTransaction,
  CancelGatewayTransactionSuccess,
  PayOnPickup,
  PayOnPickupError,
  FinalizeMealPlanTicket,
  FinalizeMealPlanTicketError,
  PayOnPickupSuccess,
  MakePaymentFailed,
  FinalizeMealPlanTicketSuccess
} from './payment.action';
import { LogRocketProvider, LOG_ROCKET_CUSTOM_EVENTS } from 'src/app/providers';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';
import { PAYMENT_STATE_TOKEN, PaymentStateModel } from './payment.state.model';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { StartIdleTime, StopIdle } from 'src/app/store/app/app.action';

@State({
  name: PAYMENT_STATE_TOKEN,
  defaults: {
    departments: [],
    businessLines: [],
    selectedGiftCard: null,
    lastGatewayTransaction: null
  }
})
@Injectable()
export class PaymentState {
  errorMsg: string;

  constructor(
    private store: Store,
    private gbService: GlobalService,
    private ticketService: TicketService,
    private paymentService: PaymentService,
    private logRocketProvider: LogRocketProvider,
    private toastController: ToastController,
    private ngZone: NgZone,
    private action$: Actions
  ) {}

  @Selector()
  static businessLines(state: PaymentStateModel) {
    return state.businessLines;
  }

  @Selector()
  static departments(state: PaymentStateModel) {
    return state.departments;
  }

  @Selector()
  static lastGatewayTransacation(state: PaymentStateModel) {
    return state.lastGatewayTransaction;
  }

  //#region Actions

  //Call the request to get the Departments
  @Action(GetBusinessLines)
  getBusinessLines({ patchState }: StateContext<PaymentStateModel>) {
    return this.gbService
      .getBusinessLines()
      .pipe(tap((businessLines) => patchState({ businessLines })));
  }

  //Call the request to get the Departments
  @Action(GetDepartments)
  getDepartments(
    { patchState }: StateContext<PaymentStateModel>,
    { search_text, business_line_id }: GetDepartments
  ) {
    return this.gbService
      .getDepartments(search_text, business_line_id)
      .pipe(tap((departments) => patchState({ departments })));
  }

  @Action(ResetDepartments)
  resetDepartments({ patchState }: StateContext<PaymentStateModel>) {
    patchState({ departments: [] });
  }

  //Call the request to make the payment
  @Action(MakePayment)
  makePayment(
    ctx: StateContext<PaymentStateModel>,
    { ticket, payment_request }: MakePayment
  ) {
    if (!ticket) {
      return;
    }

    const isCreditCardProcessing =
      payment_request.type === PaymentRequestType.CREDIT_CARD &&
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
        .credit_card_processing !== CreditCardProcessingType.None;

    if (isCreditCardProcessing) {
      ctx.dispatch(new StopIdle());
      this.action$
        .pipe(
          ofActionSuccessful(
            MakePaymentFailed,
            OnGatewayTransactionSuccess,
            OnGatewayTransactionError
          ),
          take(1)
        )
        .subscribe(() => ctx.dispatch(new StartIdleTime()));
      return this.ticketService
        .makePaymentGatewayTransaction(
          ticket.device_ticket_uuid,
          payment_request
        )
        .pipe(
          tap((response) => {
            this.logRocketProvider.track(
              LOG_ROCKET_CUSTOM_EVENTS.Transaction.InProgress,
              {
                device_ticket_uuid: ticket.device_ticket_uuid,
                type: payment_request.type
              }
            );
            ctx.patchState({
              lastGatewayTransaction: {
                ticket,
                paymentRequest: payment_request,
                result: response,
                stripe_payment_secret: '',
                stripe_pk_production: '',
                stripe_pk_test: '',
                stripe_account_id: ''
              }
            });
            ctx.dispatch(new InitiateGatewayTransaction(response));
          }),
          catchError((error: HttpErrorResponse) =>
            ctx.dispatch(
              new MakePaymentFailed(error, { ticket, payment_request })
            )
          )
        );
    }

    return this.ticketService
      .makePaymentTransactions(ticket.device_ticket_uuid, payment_request)
      .pipe(
        tap((response) => {
          this.logRocketProvider.track(
            LOG_ROCKET_CUSTOM_EVENTS.Transaction.Complete,
            {
              device_ticket_uuid: ticket.device_ticket_uuid,
              type: payment_request.type
            }
          );
          ctx.dispatch([
            new MakePaymentSuccess(ticket.device_ticket_uuid, response),
            new LoadTicketDetailSuccess(response.ticket)
          ]);
        }),
        catchError((error: HttpErrorResponse) =>
          ctx.dispatch(
            new MakePaymentFailed(error, { ticket, payment_request })
          )
        )
      );
  }

  @Action(MakePaymentFailed)
  makePaymentFailed(
    ctx: StateContext<PaymentStateModel>,
    { error, paymentData }: MakePaymentFailed
  ) {
    const isCreditCard =
      paymentData.payment_request.type === PaymentRequestType.CREDIT_CARD;
    if (isCreditCard) {
      ctx.patchState({
        lastGatewayTransaction: {
          ticket: paymentData.ticket,
          paymentRequest: paymentData.payment_request
        }
      });
    }

    const errorMessage: string = error.error?.message;
    return ctx.dispatch(
      errorMessage?.includes('Insufficient mealplan')
        ? new MealPlanPaymentError(
            paymentData.ticket.device_ticket_uuid,
            error.error?.message
          )
        : new MakePaymentError(error, paymentData)
    );
  }

  @Action(MakePaymentError)
  makePaymentError(
    ctx: StateContext<PaymentStateModel>,
    { error, posPaymentData }: MakePaymentError
  ) {
    let errorMessage =
      'There was an error processing your payment. Please try again or contact support.';
    const paymentRequest = posPaymentData.payment_request;

    if (paymentRequest.type === PaymentRequestType.CHARGE_ACCOUNT) {
      if (error.status === 422) {
        errorMessage = `Your payment was not processed due to insufficient funds remaining for the selected account. <br><br>${error?.error?.message}<br><br>
        Choose another payment option, and try again.`;
      }
    }

    ctx.dispatch(new HttpRequestError(errorMessage));
  }

  @Action(OnRetryGatewayTransaction)
  retryGatewayTransaction(ctx: StateContext<PaymentStateModel>) {
    const { lastGatewayTransaction } = ctx.getState();

    return ctx.dispatch(
      lastGatewayTransaction
        ? new MakePayment(
            lastGatewayTransaction.ticket,
            lastGatewayTransaction.paymentRequest
          )
        : new ExceptionError(
            'There was an error processing your payment. Please try again or contact support.'
          )
    );
  }

  @Action(InitiateGatewayTransaction)
  processGatewayTransaction(
    ctx: StateContext<PaymentStateModel>,
    { gatewayTransactionResult }: InitiateGatewayTransaction
  ) {
    const selectedTicketId =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;

    return this.ticketService
      .getTrancloudTransaction(
        selectedTicketId,
        gatewayTransactionResult.gateway_transaction_id
      )
      .pipe(
        tap((response) => {
          switch (response.status) {
            case 'in_progress':
              const lastGatewayTransaction =
                ctx.getState().lastGatewayTransaction;
              const isStripePaymentSecretSet =
                !!lastGatewayTransaction?.stripe_payment_secret;

              //We only need to set this once during this transaction
              if (response.stripe_payment_secret && !isStripePaymentSecretSet) {
                ctx.patchState({
                  lastGatewayTransaction: {
                    ...lastGatewayTransaction,
                    stripe_payment_secret: response.stripe_payment_secret,
                    stripe_pk_test: response.stripe_pk_test,
                    stripe_pk_production: response.stripe_pk_production,
                    stripe_account_id: response.stripe_account_id
                  }
                });
              }
              throw response;
            case 'complete':
              this.logRocketProvider.track(
                LOG_ROCKET_CUSTOM_EVENTS.Transaction.Complete,
                {
                  device_ticket_uuid: selectedTicketId,
                  transaction_id: response.transaction?.id,
                  type: response.transaction?.type
                }
              );
              ctx.dispatch([
                new OnGatewayTransactionSuccess(),
                new MakePaymentSuccess(selectedTicketId, {
                  transaction: response.transaction,
                  ticket_status: response.ticket_status,
                  new_balance: response.new_balance
                })
              ]);
              break;
            default:
              this.logRocketProvider.track(
                LOG_ROCKET_CUSTOM_EVENTS.Transaction.Declined,
                {
                  device_ticket_uuid: selectedTicketId
                }
              );

              //Default Gateway Transaction error
              let error = 'There was a problem with the gateway transaction';

              // Gateway Transaction payment_gateway_response value will be different depending on the payment processor:
              // Stripe - String value returned with readable error
              // Trancloud - Object returned, readable error within Rstream.TextResponse
              const payment_gateway_response =
                response?.payment_gateway_response;
              const RStream =
                payment_gateway_response?.RStream ||
                payment_gateway_response?.Rstream;
              const CmdStatus = RStream?.CmdStatus;
              const TextResponse = RStream?.TextResponse;

              if (typeof payment_gateway_response === 'string') {
                error = payment_gateway_response;
              } else if (TextResponse) {
                error = TextResponse;
              }

              ctx.dispatch(new OnGatewayTransactionError(error, CmdStatus));
          }
        }),
        catchError((e: HttpErrorResponse) => {
          //Action should only be dispatched if there is an actual backend error response.
          //This catchError is triggered when response status is in_progress due to the throw on the above Switch Case to trigger the retry operator.
          if (e.error?.message === 'failed') {
            return ctx.dispatch(
              new OnGatewayTransactionError('Gateway transaction cancelled')
            );
          }

          return throwError(() => e);
        }),
        retry({ delay: 2000 })
      );
  }

  @Action(OnGatewayTransactionSuccess)
  onGatewayTransactionSuccess(ctx: StateContext<PaymentStateModel>) {
    ctx.patchState({ lastGatewayTransaction: null });
  }

  @Action(GetGiftCard)
  getGiftCard(
    ctx: StateContext<PaymentStateModel>,
    { identification_number }: GetGiftCard
  ) {
    return this.gbService.getGiftCard(identification_number).pipe(
      tap((gift_card) => {
        ctx.dispatch(new GetGiftCardSuccess(gift_card));
      })
    );
  }

  @Action(CancelGatewayTransaction)
  onCancelGatewayTransaction(ctx: StateContext<PaymentStateModel>) {
    const { lastGatewayTransaction } = ctx.getState();

    return this.paymentService
      .cancelGatewayTransaction(
        lastGatewayTransaction.ticket.device_ticket_uuid,
        lastGatewayTransaction.result.gateway_transaction_id
      )
      .pipe(
        tap((response) => {
          if (response.success) {
            ctx.dispatch(new CancelGatewayTransactionSuccess());
          } else {
            let errorMessage =
              'There was a problem while cancelling the gateway transaction';

            if (response.payment_received === true) {
              errorMessage = response.error_message;
            }

            ctx.dispatch(new ExceptionError(errorMessage));
          }
        }),
        catchError(() =>
          ctx.dispatch(
            new ExceptionError(
              'There was a problem while cancelling the gateway transaction'
            )
          )
        )
      );
  }

  @Action(PayOnPickup)
  payOnPickup(
    ctx: StateContext<PaymentStateModel>,
    { deviceTicketUuid }: PayOnPickup
  ) {
    return this.paymentService.payOnPickup(deviceTicketUuid).pipe(
      tap((res) => ctx.dispatch(new PayOnPickupSuccess(res))),
      catchError(() =>
        ctx.dispatch(
          new PayOnPickupError(`There was a problem placing the order`)
        )
      )
    );
  }

  @Action(PayOnPickupSuccess)
  payOnPickupSuccess(
    ctx: StateContext<PaymentStateModel>,
    { ticket }: PayOnPickupSuccess
  ) {
    const isAutoCloseTicket =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
        .auto_close_on_full_payment;

    this.toastPlaceOrderSuccess();

    if (isAutoCloseTicket) {
      return ctx.dispatch(new RemoveTicketSuccess(ticket.device_ticket_uuid));
    }

    return ctx.dispatch(new UpdateTicketSuccess(ticket));
  }

  @Action(FinalizeMealPlanTicket)
  finalizeMealPlanTicket(
    { dispatch }: StateContext<PaymentStateModel>,
    { deviceTicketUuid }: FinalizeMealPlanTicket
  ) {
    return this.paymentService.finalizeMealPlanTicket(deviceTicketUuid).pipe(
      switchMap((res) =>
        res.success
          ? dispatch(new FinalizeMealPlanTicketSuccess(res.ticket))
          : dispatch(
              new FinalizeMealPlanTicketError(
                'There was a problem placing the order'
              )
            )
      ),
      catchError((error: HttpErrorResponse) =>
        dispatch(
          new FinalizeMealPlanTicketError(
            'There was a problem placing the order',
            error
          )
        )
      )
    );
  }

  @Action(FinalizeMealPlanTicketSuccess)
  finalizeMealPlanTicketSuccess(
    { dispatch }: StateContext<PaymentStateModel>,
    { ticket }: FinalizeMealPlanTicketSuccess
  ) {
    return dispatch(
      new RemoveTicketSuccess(ticket.device_ticket_uuid)
    ).subscribe(() => this.toastPlaceOrderSuccess());
  }
  //#endregion

  private toastPlaceOrderSuccess() {
    this.ngZone.run(async () => {
      const toast = await this.toastController.create({
        color: 'success',
        message: 'Order has been successfully placed',
        duration: 4000
      });
      await toast.present();
    });
  }
}
