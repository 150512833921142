import {
  ReservationTable,
  ReservationTimeSlot,
  ReservationTimeSlotConfig,
  Table,
  TableReservation,
  TimeSlotDisplayMode,
  TimeSlotRangeType
} from 'src/app/models';
import { PosTime, TimeRange } from '../models/time.model';
import { TimeHelper } from './time.helper';
import { DateHelper } from './date.helper';
import { TableHelper } from './table.helper';

export class ReservationHelper {
  static readonly DEFAULT_TIME_STEP = 30;

  static generateTimeSlots(
    config: ReservationTimeSlotConfig,
    reservations: TableReservation[],
    tables: Table[],
    date: string
  ) {
    const reservationsWithTimeRange = reservations.map(
      (reservation) =>
        <TableReservation & TimeRange>{
          ...reservation,
          start: reservation.start_time
            ? TimeHelper.getPosTime(reservation.start_time)
            : { hour: 0, minute: 0 },
          end: reservation.end_time
            ? TimeHelper.getPosTime(reservation.end_time)
            : { hour: 0, minute: 0 }
        }
    );

    const timeRange: TimeRange = {
      start: TimeHelper.getPosTime(config.startTime),
      end: TimeHelper.getPosTime(config.endTime)
    };

    const breakfastTimeRange: TimeRange = config.breakfast &&
      config.breakfast.startTime &&
      config.breakfast.endTime && {
        start: TimeHelper.getPosTime(config.breakfast.startTime),
        end: TimeHelper.getPosTime(config.breakfast.endTime)
      };

    const lunchTimeRange: TimeRange = config.lunch &&
      config.lunch.startTime &&
      config.lunch.endTime && {
        start: TimeHelper.getPosTime(config.lunch.startTime),
        end: TimeHelper.getPosTime(config.lunch.endTime)
      };

    const dinerTimeRange: TimeRange = config.dinner &&
      config.dinner.startTime &&
      config.dinner.endTime && {
        start: TimeHelper.getPosTime(config.dinner.startTime),
        end: TimeHelper.getPosTime(config.dinner.endTime)
      };

    const timeSeries = TimeHelper.generateTimeSeries(timeRange, {
      hour: 0,
      minute: 15
    });

    return timeSeries.reduce((prev, timeItem) => {
      let rangeType: TimeSlotRangeType = TimeSlotRangeType.DEFAULT;
      let displayMode: TimeSlotDisplayMode =
        timeItem.minute === 0
          ? TimeSlotDisplayMode.HOUR_START
          : TimeSlotDisplayMode.DEFAULT;
      let max_capacity_total = 0;
      let max_seatings = 0;

      if (
        breakfastTimeRange &&
        TimeHelper.isTimeInRange(timeItem, breakfastTimeRange, {
          ignoreEnd: true
        })
      ) {
        rangeType = TimeSlotRangeType.BREAKFAST;
        if (config.breakfast.max_capacity_total) {
          max_capacity_total = config.breakfast.max_capacity_total;
          max_seatings =
            config.breakfast.max_capacity_total ||
            config.breakfast.total_number_of_seats;
        }

        if (TimeHelper.compareTime(timeItem, breakfastTimeRange.start) === 0) {
          displayMode = TimeSlotDisplayMode.RANGE_START;
        }
      }

      if (
        lunchTimeRange &&
        TimeHelper.isTimeInRange(timeItem, lunchTimeRange, { ignoreEnd: true })
      ) {
        rangeType = TimeSlotRangeType.LUNCH;
        if (config.lunch.max_capacity_total) {
          max_capacity_total = config.lunch.max_capacity_total;
          max_seatings =
            config.lunch.max_capacity_total ||
            config.lunch.total_number_of_seats;
        }

        if (TimeHelper.compareTime(timeItem, lunchTimeRange.start) === 0) {
          displayMode = TimeSlotDisplayMode.RANGE_START;
        }
      }

      if (
        dinerTimeRange &&
        TimeHelper.isTimeInRange(timeItem, dinerTimeRange, { ignoreEnd: true })
      ) {
        rangeType = TimeSlotRangeType.DINER;
        if (config.dinner.max_capacity_total) {
          max_capacity_total = config.dinner.max_capacity_total;
          max_seatings =
            config.dinner.max_capacity_total ||
            config.dinner.total_number_of_seats;
        }

        if (TimeHelper.compareTime(timeItem, dinerTimeRange.start) === 0) {
          displayMode = TimeSlotDisplayMode.RANGE_START;
        }
      }

      if (
        rangeType === TimeSlotRangeType.DEFAULT &&
        prev.length > 0 &&
        prev[prev.length - 1].rangeType !== rangeType
      ) {
        displayMode = TimeSlotDisplayMode.RANGE_START;
      }

      let seats_reserved = 0;
      const reservationsWithinTimeSlot = reservationsWithTimeRange.filter(
        ({ start, end }) =>
          TimeHelper.isTimeInRange(
            timeItem,
            { start, end },
            {
              ignoreEnd: true
            }
          )
      );

      if (max_capacity_total) {
        seats_reserved = reservationsWithinTimeSlot
          .map((reservation) =>
            reservation.reservation_tables.reduce((prevValue, currTable) => {
              const targetTable = tables.find(
                (table) => table.id === currTable.table_id
              );
              if (!targetTable) {
                return prevValue;
              }

              return prevValue + targetTable.seats.length;
            }, 0)
          )
          .reduce((prevValue, currValue) => prevValue + currValue, 0);
      }

      const number_available_tables =
        tables.length -
        tables.filter(
          (table) =>
            !!reservationsWithinTimeSlot.find((reservation) =>
              reservation.reservation_tables.some(
                (reservedTable) => reservedTable.table_id === table.id
              )
            )
        ).length;

      const reservationTimeSlot: ReservationTimeSlot = {
        time: timeItem,
        date,
        displayMode,
        rangeType,
        max_capacity_total,
        max_seatings,
        seats_reserved,
        number_available_tables:
          number_available_tables >= 0 ? number_available_tables : 0
      };

      return [...prev, reservationTimeSlot];
    }, [] as ReservationTimeSlot[]);
  }

  static getTimeDuration(startTime: string, endTime: string) {
    const start: PosTime = startTime
      ? TimeHelper.getPosTime(startTime)
      : { hour: 0, minute: 0 };
    const end: PosTime = endTime
      ? TimeHelper.getPosTime(endTime)
      : { hour: 0, minute: 0 };

    if (TimeHelper.compareTime(start, end) > 0) {
      return 0;
    }

    const duration = TimeHelper.getTimeDurationFromTimeRange({ start, end });

    return duration.hour + duration.minute / 60;
  }

  static insertReservedTableStatus(
    tables: Table[],
    reservedTables: ReservationTable[],
    selectedReservedTables: ReservationTable[],
    startTime: string,
    endTime: string,
    date: string
  ) {
    const currentDate = date || DateHelper.format(new Date());
    const start = TimeHelper.getPosTime(startTime || '00:00');
    const end = TimeHelper.getPosTime(
      startTime && !endTime ? startTime : endTime || '23:59'
    );

    tables.forEach((table) => {
      const foundSelectedTable = selectedReservedTables.find(
        (selectedTable) => selectedTable.table_id === table.id
      );

      if (!!foundSelectedTable) {
        return TableHelper.modifyingSelectedTable(table);
      }

      const foundReservation = reservedTables.find((reservedTable) => {
        if (reservedTable.table_id !== table.id) {
          return false;
        }

        const reservedRangeTime: TimeRange = {
          start: TimeHelper.getPosTime(reservedTable.local_attrs.start_time),
          end: TimeHelper.getPosTime(reservedTable.local_attrs.end_time)
        };

        return (
          reservedTable.table_id === table.id &&
          currentDate === reservedTable.local_attrs.date &&
          TimeHelper.isTimeOverlap({ start, end }, reservedRangeTime)
        );
      });

      if (!!foundReservation) {
        return TableHelper.modifyingReservedTable(table);
      }
    });

    return tables;
  }

  static modifyReservationTable(
    table: ReservationTable,
    reservation: TableReservation
  ): ReservationTable {
    return {
      ...table,
      local_attrs: {
        start_time: reservation.start_time,
        end_time: reservation.end_time,
        date: reservation.date,
        reservationId: reservation.id
      }
    };
  }

  static getTimeStringFromSlideValue(
    slideValue: number,
    startTime: string,
    timeStep: number
  ) {
    if (slideValue < 0) {
      throw new Error('Invalid Slide Value');
    }

    const totalMin = slideValue * (timeStep || this.DEFAULT_TIME_STEP);
    const hour = Math.floor(totalMin / 60);
    const minute = totalMin - hour * 60;

    const endTime = TimeHelper.addTime(
      startTime ? TimeHelper.getPosTime(startTime) : { hour: 0, minute: 0 },
      { hour, minute }
    );

    return TimeHelper.getTimeString(endTime.hour, endTime.minute);
  }

  static isPastReservation(reservation: TableReservation) {
    const currentTime = new Date();
    const currentDateString = DateHelper.format(currentTime);
    const isPastDate = () =>
      DateHelper.parse(reservation.date).getTime() <
      DateHelper.parse(currentDateString).getTime();
    const isCurrentDate = () => reservation.date === currentDateString;
    const isPastTime = () =>
      TimeHelper.compareTime(TimeHelper.getPosTime(reservation.start_time), {
        hour: currentTime.getHours(),
        minute: currentTime.getMinutes()
      }) < 0;

    return isPastDate() || (isCurrentDate() && isPastTime());
  }

  /** Sunday is 0 in JS but 7 in the backend
   * @param date
   * @returns 1: Monday, 2: Tuesday, 3: Wednesday, 4: Thursday, 5: Friday, 6: Saturday, 7: Sunday
   */
  static getReservationDay(date: string): number {
    //Gets the day of the week, using local time. (0-6)
    const dayValue = DateHelper.parse(date).getDay();
    const isSunday = dayValue === 0;

    return isSunday ? 7 : dayValue;
  }
}
